import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import Img from "gatsby-image";
import SEO from '../../components/seo';
import ImageLandscape from '../../components/sub/landscape.js';
export const frontmatter = {
  title: "Modern Frenemies: A New Collaborative Movement",
  date: "2017-07-05",
  author: "Roberto Ramos",
  authorTitle: "Senior Vice President, Strategy and Communications of The Doneger Group",
  teaserImage: "../../../static/media/20170705-0.jpg",
  path: "2017-07-05-Modern-Frenemies",
  teaserText: "A more discriminating and mobile consumer is driving innovation and competition in key industries."
};
const layoutProps = {
  frontmatter
};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>

      <SEO title="Modern Frenemies: A New Collaborative Movement" description="A more discriminating and mobile consumer is driving innovation and competition in key industries." author='Roberto Ramos' keywords={['Robots']} />
      <MDXTag name="p" components={components}>{`A more discriminating and mobile consumer is driving innovation and competition in key industries. They are demanding more personalized, hyper-functional and experiential products. Direct-to-consumer businesses, flush with start-up ambition, tech chops, VC-minted cash and the freedom of not having to be profitable now, are running giddily and diligently into this space, and delivering on-demand personalization at scale. The consumer is eating it up, disrupting one industry after another and forcing traditional players to innovate, ushering innovation titles galore.`}</MDXTag>
      <ImageLandscape imageSrc="20170705-1.jpg" caption="Lego" />
      <MDXTag name="p" components={components}>{`I see the world of start-ups and emerging enterprises coming together with the corporate as they both address the growth and sustainability challenges of their respective models. A collaborative answer might lie somewhere in the middle, with former competitors exploring how they might work together to tackle challenges, opportunities and an 800 lb. common threat (six letter word beginning with an A, take a guess).`}</MDXTag>
      <ImageLandscape imageSrc="20170705-2.jpg" caption="Dollar Shave Club" />
      <MDXTag name="p" components={components}>{`Smaller companies and digital ventures will seek the security and scale of bigger partners while larger companies will seek to bring in-house the innovation, digital competency, unique product pipeline and elusive consumer segments that make smaller digital plays so sexy. I expect to see more plays like Unilever’s acquisition of Dollar-Shave Club. I also foresee more big companies launching their own venture capital and accelerator programs to tap into the innovation that flows from either existing talent or budding collaborations.`}</MDXTag>
      <MDXTag name="p" components={components}>{`These types of acquisitions and collaborations, unlike the corporate mergers of equals of yore, in theory will bring complimentary assets to the expanded organization, reducing the “who moved my cheese” fears of lay-offs that saddled merged cultures from the onset. These modern acquisitions should prove more harmonious as overall corporate culture has come to embrace the work lifestyle balance and approach of start-ups. Still key will be the ability to give each side what it wants, in the case of the smaller unit, semi-autonomy and freedom to protect their entrepreneurial status.`}</MDXTag>
      <ImageLandscape imageSrc="20170705-3.jpg" caption="Aloft Hotel" />
      <MDXTag name="p" components={components}>{`The marriage of small and big will play out in many other ways as well. We expect more partnerships like those of Casper rolling out its mattress products at Target and Warby Parker’s pop-ups at Nordstrom, giving a consumer both the familiarity and convenience of big box retailers along with the hipness of next gen brands. Big and small will also come to life with big brands creating more limited, niche product offerings. In the hospitality category, for instance, Starwood has rolled out Aloft, a more boutique, urbane offering that offers a more personal, anti-big chain experience.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Driven by a consumer that wants both, especially the older millennial consumer that is starting a family and wants the convenience, scale and resources of big with the more differentiated and personalized feeling of new brands, is ultimately fueling this convergence between big and small. In essence they want both their Amazon and organic beauty product experience rolled up in one, simple journey, both online and physically.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Another driver of this merge is the convergence of online and physical commerce into one seamless, omni-personal experience. Physical retail is not going away, quite the opposite, I reckon it will matter more as we move back to cities seeking a better quality of life. Physical retail will need to become more powerfully relevant, tapping into the personalization prowess of digital. And this again is how both direct-to-consumer hot brands and more traditional retail players can collaborate.`}</MDXTag>
      <MDXTag name="p" components={components}>{`The following are some other ways in which big and small will coalesce:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Big data ushers small data: In advertising, for instance, we’ve seen the beginning of hyper-targeting but this will get better as AI delivers more intelligent, intimate and smaller scale forms of engagement that will resonate more and deliver better and bigger conversion rates.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`The work setting will feel smaller and more intimate. Big corporations competing for the best talent will have to take lessons from the new start-up players and cultures. Think more intimate, inspiring settings and a more human-focused approach to talent.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`In media I expect the continuation of niche offerings by big companies in order to address the big decoupling/cord-cutting from the traditional distribution gatekeepers. New distribution companies like Netflix are addressing this with targeted offerings that make big seem small.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`What’s happening here distills the human contradiction, our vast ambition for scale with our emotional attraction to what feels small. The cell phone has empowered this contradiction, becoming our modern day warrior compass. For such a small gadget, it sure can deliver a lot. It’s this personalized bigness that we want and what the Davids and Goliaths will now have to work together to deliver. Here’s to frenemies making peace, and, why not, money.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`What does this mean for you?`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`You need to become ambidextrous in the planes of big and small. Envision scale but map out the details. Speak corporate language and methodologies but also embrace the dynamism, change, fear and iterative testing of start-up culture. Study and live both sides so you can serve as the needed bridge between the two worlds, a bridge that will increasingly go up in value. Go ahead and become your best David and Goliath.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    